import axios from 'axios';
import isUrl from 'is-url';
import joinUrl from 'proper-url-join';

import { checkIsTokenExpired, clearToken } from './utility';

class APIRequest {
  constructor(baseURL, httpsAgent, httpAgent, getToken) {
    if (!isUrl(baseURL)) throw new Error('The base URL provided is not valid');

    this.baseURL = baseURL;
    this.httpsAgent = httpsAgent;
    this.httpAgent = httpAgent;
    this.getToken = getToken;
  }

  async request(method, url, data) {
    let callURL = joinUrl(this.baseURL, url, { trailingSlash: false });

    const headers = {};
    const token = this.getToken();

    if (token) {
      headers['Authorization'] = `Bearer ${token.get('accessToken')}`;
    }

    if (['POST', 'PUT'].indexOf(method) !== -1) {
      headers['Content-Type'] = 'application/json';
    } else if (Object.keys(data).length && data.constructor === Object) {
      callURL = joinUrl(callURL, { trailingSlash: false, query: data });
    }

    return axios(callURL, {
      httpsAgent: this.httpsAgent,
      httpAgent: this.httpAgent,
      method,
      data,
      headers
    }).then(
      (response) => {
        if (response.status >= 400) {
          // check for 4XX, 5XX, wtv
          return Promise.reject(new Error(response.statusText));
        }

        if (response.status >= 200 && response.status <= 202) {
          return response.data;
        }

        return {};
      },
      async (error) => {
        const response = error.response;
        // If error 401 UNAUTHORIZED (invalid token) to refresh token & retry calling the API
        if (response.status === 401 && response.data === 'Access token is invalid') {
          const { data: refreshed } = await this.request('POST', '/auth/token', {
            refreshToken: token.get('refreshToken')
          });

          window.localStorage.setItem('id_token', refreshed.idToken);
          window.localStorage.setItem('access_token', refreshed.accessToken);
          window.localStorage.setItem('refresh_token', refreshed.refreshToken);

          headers['Authorization'] = `Bearer ${refreshed.accessToken}`;

          return axios(callURL, {
            httpsAgent: this.httpsAgent,
            httpAgent: this.httpAgent,
            method,
            data,
            headers
          }).then((response) => {
            if (response.status >= 400) {
              // check for 4XX, 5XX, wtv
              return Promise.reject(new Error(response.statusText));
            }

            if (response.status >= 200 && response.status <= 202) {
              return response.data;
            }

            return {};
          });
        }

        if (error.response) {
          return Promise.reject(error.response);
        }

        return Promise.reject(error);
      }
    );
  }

  async checkToken() {
    const token = this.getToken();
    const accessToken = token.get('accessToken');

    if (window.DEBUG_TOKEN) {
      console.log('----------');
      console.log('token', !!token);
      console.log('accessToken', !!accessToken);
    }

    if (!accessToken) {
      return;
    }

    const isExpired = checkIsTokenExpired(accessToken);

    if (isExpired) {
      console.log('Refreshing token...');

      try {
        const { data: refreshed } = await this.request('POST', '/auth/token', {
          refreshToken: token.get('refreshToken')
        });

        window.localStorage.setItem('id_token', refreshed.idToken);
        window.localStorage.setItem('access_token', refreshed.accessToken);
        window.localStorage.setItem('refresh_token', refreshed.refreshToken);

        console.log('Token refreshed successfully!');
      } catch (error) {
        console.log('Token fail to refresh!');
        clearToken();
        throw error;
      }
    }
  }

  async send(method, url, data = {}, checkToken = true) {
    if (checkToken) {
      await this.checkToken();
    }

    const response = await this.request(method, url, data);
    return response;
  }
}

class HSBB2 {
  constructor(options) {
    this.options = options;
    this.baseURL = options.baseURL;

    this.api = new APIRequest(options.baseURL, options.httpsAgent, options.httpAgent, options.getToken);
  }

  // users: start
  createUser = (userData) => {
    return this.api.send('POST', '/users', userData);
  };

  getUsers = (options) => {
    return this.api.send('GET', '/users', options);
  };

  getUser = (id) => {
    return this.api.send('GET', `/registrations/fibre-mass/fpfp/user/${id}`);
  };

  getUserRoles = (userData) => {
    return this.api.send('POST', `/registrations/fibre-mass/fpfp/user/roles`, userData);
  };

  updateUser = (id, userData) => {
    return this.api.send('PUT', `/users/${id}`, userData);
  };

  deleteUser = (id) => {
    return this.api.send('DELETE', `/users/${id}`);
  };
  // users: end

  //fibre-mass: start
  listFibreMass = (options) => {
    return this.api.send('GET', '/subscriptions/fibre-mass', options);
  };

  listFibreCustomerPostpaid = (id) => {
    return this.api.send('GET', `/billing/fibre-mass/${id}/postpaid`);
  };

  reserveFibreMassSim = (id, body) => {
    return this.api.send('PUT', `/billing/fibre-mass/${id}/iccid`, body);
  };

  reserveFibreMassMSISDN = (id, body) => {
    return this.api.send('PUT', `/billing/fibre-mass/${id}/msisdn`, body);
  };

  updateFibreMassAccountCode = (id, body) => {
    return this.api.send('PUT', `registrations/fibre-mass/${id}/account-code`, body);
  };
  //fibre-mass: end

  // fibre-mass appointment: start
  createOrRescheduleFibreMassAppointment = (key_id, body) => {
    return this.api.send('PUT', `/installations/fibre-mass/${key_id}/appointment`, body);
  };
  // fibre-mass appointment: end

  // broadband-mass: start
  listBroadbandsMass = (options) => {
    return this.api.send('GET', '/subscriptions/broadband-mass', options);
  };

  getBroadbandMassByKeyId = (key_id) => {
    return this.api.send('GET', `/subscriptions/broadband-mass/${key_id}`);
  };

  createBroadbandMass = (broadbandMass) => {
    return this.api.send('POST', `/registrations/broadband-mass`, broadbandMass);
  };

  updateBroadbandMass = (changes, key_id) => {
    return this.api.send('PUT', `/registrations/broadband-mass/${key_id}`, changes);
  };

  addBroadbandMassReleaseLetter = (key_id, file) => {
    return this.api.send('PUT', `/registrations/broadband-mass/${key_id}/release-letter`, file);
  };

  removeBroadbandMassReleaseLetter = (key_id, index, filename) => {
    return this.api.send('DELETE', `/registrations/broadband-mass/${key_id}/release-letter/${index}`, filename);
  };

  verifyBroadbandMass = (key_id) => {
    return this.api.send('PUT', `/registrations/broadband-mass/${key_id}/verification`);
  };

  cancelBroadbandMass = (key_id, body) => {
    return this.api.send('PUT', `/registrations/broadband-mass/${key_id}/cancellation`, body);
  };

  addBroadbandMassSAF = (key_id, file) => {
    return this.api.send('PUT', `/subscriptions/broadband-mass/${key_id}/service-acceptance-form`, file);
  };

  removeBroadbandMassSAF = (key_id, index, filename) => {
    return this.api.send(
      'DELETE',
      `/subscriptions/broadband-mass/${key_id}/service-acceptance-form/${index}`,
      filename
    );
  };

  updateBBMassCompletionStatus = (id) => {
    return this.api.send('PUT', `/subscriptions/broadband-mass/${id}/completion`);
  };

  terminateBroadbandMass = (key_id, body) => {
    return this.api.send('PUT', `/subscriptions/broadband-mass/${key_id}/termination`, body);
  };
  // broadband-mass: end

  // broadband-mass billing: start
  getBBMassPostpaidBilling = (key_id) => {
    return this.api.send('GET', `/billing/broadband-mass/${key_id}/postpaid`);
  };

  validateBBMassSIM = (key_id, body) => {
    return this.api.send('PUT', `/billing/broadband-mass/${key_id}/iccid`, body);
  };

  reserveBBMassMSISDN = (key_id) => {
    return this.api.send('PUT', `/billing/broadband-mass/${key_id}/msisdn`);
  };

  updateBBMassAccountCode = (key_id, body) => {
    return this.api.send('PUT', `/registrations/broadband-mass/${key_id}/account-code`, body);
  };
  // broadband-mass billing: end

  // broadband-mass release_letter: start
  getBBMassReleaseLetterDownloadUrl = (key_id, index) => {
    return this.api.send('GET', `/registrations/broadband-mass/${key_id}/release-letter/${index}`);
  };

  getBBMassReleaseLetterUploadUrl = (key_id, body) => {
    return this.api.send('POST', `/registrations/broadband-mass/${key_id}/release-letter`, body);
  };

  approveBBMassReleaseLetter = (key_id) => {
    return this.api.send('PUT', `/registrations/broadband-mass/${key_id}/release-letter/approval`);
  };
  // broadband-mass release_letter: end

  // broadband-mass saf: start
  getBBMassSAFDownloadUrl = (key_id, index) => {
    return this.api.send('GET', `/subscriptions/broadband-mass/${key_id}/service-acceptance-form/${index}`);
  };

  getBBMassSAFUploadUrl = (key_id, body) => {
    return this.api.send('POST', `/subscriptions/broadband-mass/${key_id}/service-acceptance-form`, body);
  };
  // broadband-mass saf: end

  // broadband-mass appointment: start
  createOrRescheduleBBMassAppointment = (key_id, body) => {
    return this.api.send('PUT', `/installations/broadband-mass/${key_id}/appointment`, body);
  };

  updateBBMassAppointmentStatus = (key_id, body) => {
    return this.api.send('PUT', `/installations/broadband-mass/${key_id}/appointment/status`, body);
  };
  // broadband-mass appointment: end

  // broadband-mass installer: start
  updateBBMassInstaller = (key_id, body) => {
    return this.api.send('PUT', `/installations/broadband-mass/${key_id}/installer`, body);
  };

  getBBMassInstaller = (key_id) => {
    return this.api.send('GET', `/installations/broadband-mass/${key_id}/installers`);
  };
  // broadband-mass installer: end

  // broadband-mass flag : start
  updateBBMassFlagMessage = (id, body) => {
    return this.api.send('PUT', `/registrations/broadband-mass/${id}/flag-message`, body);
  };
  // broadband-mass flag : end

  // plans
  getPlans = (options) => {
    return this.api.send('GET', `/plans`, options);
  };

  // Malaysia States
  getStates = () => {
    return this.api.send('GET', `/lists/states`);
  };

  // Malaysia Cities
  getCities = (stateId) => {
    return this.api.send('GET', `/lists/states/${stateId}/cities`);
  };

  // Countries
  getCountries = () => {
    return this.api.send('GET', `/lists/countries`);
  };

  //get history
  getBBHistory = (key_id) => {
    return this.api.send('GET', `/history/broadband-mass/${key_id}`);
  };

  appendBBHistory = (key_id, updateData) => {
    return this.api.send('PUT', `/applications/${key_id}/history`, updateData);
  };

  // broadband delay reasons: start
  getDelayResponse = (id) => {
    return this.api.send('GET', `/registrations/broadband-mass/${id}/delay-status`);
  };

  updateDelayApplication = (id, body) => {
    return this.api.send('PUT', `/registrations/broadband-mass/${id}/delay-reason`, body);
  };

  //fibre-mass billing: start

  listFibreCustomerPostpaid = (id) => {
    return this.api.send('GET', `/billing/fibre-mass/${id}/postpaid`);
  };

  reserveFibreMassSim = (id, body) => {
    return this.api.send('PUT', `/billing/fibre-mass/${id}/iccid`, body);
  };

  /**
   * @param {String} session, fibre-mass session
   * @param {Object} body, updated body
   * @returns {Promise}
   */
  updateFibreFlagMessage = (session, body) => {
    return this.api.send('PUT', `/registrations/fibre-mass/${session}/flag-message`, body)
  }

  updateCelcomFibreFlagMessage = (session, body) => {
    return this.api.send('PUT', `/registrations/fibre-mass/fpfp/${session}/celcom-flag-message`, body)
  }

  updateModifiedStatusToTask = (body) => {
    return this.api.send('PUT', `/user-group/modified`, body)
  }

  reserveFibreMassMSISDN = (id, body) => {
    return this.api.send('PUT', `/billing/fibre-mass/${id}/msisdn`, body);
  };

  updateFibreMassAccountCode = (id, body) => {
    return this.api.send('PUT', `registrations/fibre-mass/${id}/account-code`, body);
  };
  //fibre-mass billing: end

  getBroadbandDelayReasons = () => {
    return this.api.send('GET', '/lists/broadband/delay-reasons');
  };
  // broadband delay reasons: end

  // broadband cancel reasons: start
  getBroadbandCancelReasons = () => {
    return this.api.send('GET', `/lists/broadband/cancel-reasons`);
  };
  // broadband cancel reasons: end

  //radius start
  getProfile = (id) => {
    return this.api.send('GET', `/radius/${id}`);
  };

  updateProfile = (id, body) => {
    return this.api.send('PUT', `/radius/${id}/password`, body);
  };

  getSessionHistory = (username) => {
    return this.api.send('GET', `/radius/${username}/session-history`);
  };

  listRadiusProfiles = (options) => {
    return this.api.send('GET', '/radius', options);
  };

  listGroupName = (options) => {
    return this.api.send('GET', '/radius/groups', options);
  };
  // radius : end

  // Address: start
  listAddress = (options) => {
    return this.api.send('GET', `/address`, options);
  };
  // Address: end

  // COP START
  getEligiblePlans = (id) => {
    return this.api.send('GET', `/billing/fibre-mass/${id}/eligible-change-plan`);
  };

  checkCustomerEligibility = (id) => {
    return this.api.send('PUT', `/billing/fibre-mass/${id}/customer-eligibility`);
  };

  updateChangePlan = (id, body) => {
    return this.api.send('PUT', `/subscriptions/fibre-mass/${id}/change-plan`, body);
  };
  // COP END

  // fibre-mass registration: start

  createFibreMass = (fibreMass) => {
    return this.api.send('POST', `/registrations/fibre-mass`, fibreMass);
  };


  updateFibreMass = (changes, key_id) => {
    return this.api.send('PUT', `/registrations/fibre-mass/${key_id}`, changes);
  };

  createRadius = (id) => {
    return this.api.send('POST', `/registrations/fibre-mass/${id}/radius`);
  };

  updateRadius = (id, body) => {
    return this.api.send('PUT', `/registrations/fibre-mass/${id}/radius`, body);
  };

  updatePartnerReferenceId = (id, body) => {
    return this.api.send('PUT', `/registrations/fibre-mass/${id}/partner-reference`, body);
  };

  addFibreMassReleaseLetter = (id, body) => {
    return this.api.send('PUT', `/registrations/fibre-mass/${id}/release-letter`, body);
  };

  deleteFibreMassReleaseLetter = (id, index, body) => {
    return this.api.send('DELETE', `/registrations/fibre-mass/${id}/release-letter/${index}`, body);
  };

  addFibreMassRegistrationFile = (id, body) => {
    return this.api.send('PUT', `/registrations/fibre-mass/${id}/registration-document/`, body);
  };
  deleteFibreMassRegistrationFile = (id, index, body) => {
    return this.api.send('DELETE', `/registrations/fibre-mass/${id}/registration-document/${index}`, body);
  };
  getFibreMassReleaseLetterUrl = (id, index) => {
    return this.api.send('GET', `/registrations/fibre-mass/${id}/release-letter/${index}`);
  };

  getFibreMassReleaseLetterUploadUrl = (id, body) => {
    return this.api.send('POST', `/registrations/fibre-mass/${id}/release-letter`, body);
  };

  getRegistrationDocumentUploadUrl = (id, body) => {
    return this.api.send('POST', `/registrations/fibre-mass/${id}/registration-document`, body);
  };

  getRegistrationDocumentUrl = (id, index) => {
    return this.api.send('GET', `/registrations/fibre-mass/${id}/registration-document/${index}`);
  };
  approveFibreMassReleaseLetter = (id) => {
    return this.api.send('PUT', `/registrations/fibre-mass/${id}/release-letter/approval`);
  };

  verifyFibreMassCustomerEligibility = (id) => {
    return this.api.send('PUT', `/registrations/fibre-mass/${id}/verification`);
  };
  verifyProfile = (body) => {
    return this.api.send('POST', `/registrations/fibre-mass/fpfp/profile-search`, body);
  };
  cancelFiberMass = (key_id, body) => {
    return this.api.send('PUT', `/registrations/fibre-mass/fpfp/${key_id}/cancel`, body);
  };
  cancelAppointment = (key_id, body) => {
    return this.api.send('POST', `/registrations/fibre-mass/fpfp/${key_id}/cancel-appointment`, body);
  };
  rescheduleEligibilityCheck = (key_id) => {
    return this.api.send('PUT', `/registrations/fibre-mass/fpfp/${key_id}/reschedule-eligibility-check`);
  };
  
  searchPostcode = (postcode) => {
    return this.api.send('GET', `/registrations/fibre-mass/fpfp/search-postcode/${postcode}`);
  };

  retrieveAddress = (state, city, postcode, keyword, limit, offset, filterParam) => {
    return this.api.send('GET', `registrations/fibre-mass/fpfp/address-search?state=${state}&city=${city}&postcode=${postcode}&keyword=${keyword}&limit=${limit}&offset=${offset}&filterBy=${filterParam}`);
  };

  checkBlacklist = (body) => {
    return this.api.send('POST', `/registrations/fibre-mass/fpfp/blacklist`, body);
  };

  checkOpenOrderCount = (id, serviceType) => {
    return this.api.send('GET', `registrations/fibre-mass/fpfp/open-order-count?customerId=${id}&serviceType=${serviceType}`);
  };

  retrievePlans = () => {
    return this.api.send('GET', `registrations/fibre-mass/fpfp/retrieve-plans?arg0=0&arg1=60,61`);
  };

  retrieveAddOns = (plan) => {
    return this.api.send('GET', `registrations/fibre-mass/fpfp/addon-mesh?sblBundlePartNo=${plan}`);
  };

  retrieveAddOnsATG = (plan) => {
    return this.api.send('GET', `registrations/fibre-mass/fpfp/addon-mesh-atg?sblBundlePartNo=${plan}`);
  };

  submitApplication = (session, data) => {
    return this.api.send('POST', `/registrations/fibre-mass/fpfp/application/${session}/submit`, data);
  };

  reserveVOBB = (session) => {
    return this.api.send('PUT', `/registrations/fibre-mass/fpfp/${session}/reserve-user`);
  };

  retrieveInstallSlots = (state, postCode, activityId, earliestStart, district, incumbent, appointmentType) => {
    return this.api.send('GET', `registrations/fibre-mass/fpfp/retrieve-installer-slots?state=${state}&activityId=${activityId}&postCode=${postCode}&earliestStart=${earliestStart}&district=${district}&incumbent=${incumbent}&appointmentType=${appointmentType}`);
    //registrations/fibre-mass/fpfp/retrieve-installer-slots?postCode=88450&state=SB&activityId=1-1R2NX123&earliestStart=18/02/2024 16:00:00&district=KOTA KINABALU&incumbent=TM&appointmentType=OSI
  };

  confirmAppointment = (session, data) => {
    return this.api.send('POST', `/registrations/fibre-mass/fpfp/${session}/confirm-appointment`, data);
  };

  rescheduleAppointment = (session, data) => {
    return this.api.send('POST', `/registrations/fibre-mass/fpfp/${session}/reschedule-appointment`, data);
  };

  saveServiceAddressOrder = (session, data) => {
    return this.api.send('POST', `/registrations/fibre-mass/fpfp/${session}/save-service-address`, data);
  };

  updateCreatorID = (session, data) => {
    return this.api.send('POST', `/registrations/fibre-mass/fpfp/${session}/update-creator`, data);
  };

  retrieveServiceDetails = (Id, Number) => {
    return this.api.send('GET', `/registrations/fibre-mass/fpfp/address-details-retrieve?serialNumber=${Number}&subscriberId=${Id}`);
  };

  getApplication = (session) => {
    return this.api.send('GET', `/registrations/fibre-mass/fpfp/application/${session}`);
  };

  retrievePlanCatalog = (body) => {
    return this.api.send('POST', `/registrations/fibre-mass/fpfp/retrieve-plan-catelog`, body);
  }

  retrievePlanATG = (body) => {
    return this.api.send('POST', `/registrations/fibre-mass/fpfp/retrieve-plans-atg`, body);
  }

  // fibre-mass registration: end

  // Promotions-config
  getPromotionConfig = (planId) => {
    return this.api.send('GET', `/promotion/${planId}`);
  };

  getAllPromotionConfig = (options) => {
    return this.api.send('GET', '/promotion', options);
  };

  createPromotionConfig = (data) => {
    return this.api.send('POST', '/promotion/create', data);
  };

  updatePromotionConfig = (promotionId, promotionConfig) => {
    return this.api.send('PUT', `/promotion/${promotionId}`, promotionConfig);
  };

  getPromotionsByName = (name) => {
    return this.api.send('GET', `/promotion?name=${name}`);
  };
  getPromotionsByType = (type) => {
    return this.api.send('GET', `/promotion?type=${type}&is_active=true&date=today`);
  };

  updateSettings = (keyId, updateData) => {
    return this.api.send('PUT', `/user-group/update-settings/${keyId}`, updateData);
  };
  getTaskDataList = (search) => {
    const group = (search?.group) ? `&group=${search.group}` : ''
    const vid = (search?.vid) ? `&vid=${search.vid}` : ''

    return this.api.send('GET', `/user-group/get-task-assignment?type=user${group}${vid}`);
  };

  getUnassignedTasksList = (data) => {
    return this.api.send('POST', `/user-group/report-by-filter`, data);
  };
  
  updateTaskAssignment = (updateData) => {
    return this.api.send('PUT', `/user-group/update-task-assignment`, updateData);
  };

  getCopList = (params) => {
    let queryString = ''
    if(params.idType && params.idNumber){
      queryString = `?id_type=${params.idType}&id_number=${params.idNumber}`
    }
    return this.api.send('GET', `/cop/list-cop-request${queryString}`);
  };

  getTaskUploadUrl = (uploadData) => {
    return this.api.send('POST', `/user-group/upload-taskassign`, uploadData);
  };
  uploadWfmFile = (uploadData) => {
    return this.api.send('POST', `/user-group/upload-wfm-file`, uploadData);
  };

  getTaskAssignReport = (options) => {
    return this.api.send('POST', '/user-group/get-task-report', options);
  };

  getLeadTaskAssignReport = (options) => {
    return this.api.send('POST', '/user-group/get-lead-task-report', options);
  };

  getNotificationList = (storeId) => {
    return this.api.send('GET', `/flag-notification/get-flag-notification?storeId=${storeId}`);
  };

  updateApplicationReadStatus = (updateData) => {
    return this.api.send('PUT', `/flag-notification/update-flag-notification`, updateData);
  };

  getApplicationsByCreator = (userId) => {
    return this.api.send('GET', `/flag-notification/get-application-list?createdBy=${userId}`);
  };

  searchTroubleshootList = (params) => {
    let queryString = ''
    if(params.custType){
      if(params.custType === 'service_no'){
        queryString = `?msisdn=${params.custID}`
      }
      else{
        queryString = `?custType=${params.custType}&custID=${params.custID}`
      }
    }
    return this.api.send('GET', `registrations/fibre-mass/ts/search-customer-services${queryString}`);
  };

  getTroubleShootingDetails = (params) => {
    let queryString = `?serialNumber=${params.serialNumber}&subscriberId=${params.subscriberId}&fetchAddressDetail=${params.fetchAddressDetail}`
    return this.api.send('GET', `registrations/fibre-mass/ts/trouble-shooting-details${queryString}`);
  };

  createCop = (payload) => {
    return this.api.send('POST', '/cop/create-cop-request', payload);
  };
  updateCop = (copId, copData) => {
    return this.api.send('PUT', `cop/update-cop-request/${copId}`, copData);
  };
  getCop = (copId) => {
    return this.api.send('GET', `/cop/get-cop-request/${copId}`);
  };

}

export default HSBB2;
